import React, { Component } from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import triberdata from '../../triberdata.json'
import HeroImage from '../components/heroImage'
import HeroCaseStudy from '../components/heroCaseStudy'
import TextBlockCentered from '../components/textBlockCentered'
import IntroCaseStudy2 from '../components/introCaseStudy-2col.js'
import FooterCaseStudyBack from '../components/footerCaseStudyBack';

const SectionHeroCaseStudy = styled.div`
width: 100%;
`
const SectionIntroCaseStudy = styled.div`
width: 100%;
`
const SectionTextBlockCentered = styled.div`
width: 100%;
`
 const FooterContainer = styled.div`
  width: 80%;
  margin: 8em auto 4em auto; 
  font-weight: 700;
`
 const LeftContainer = styled.div`
  width: 50%;
  display: inline-block;
`
 const RightContainer = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
`
 const PreviousLink = styled.div`
  width: 50%;
  text-align: left;
  display: flex;
  align-items: center;
`
 const NextLink = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
  align-items: center;
`


import Layout from "../components/layout"

export default class Triber extends Component {
	render () {
		return (
		<Layout>
			<div>
				<SectionIntroCaseStudy>
					{triberdata.heroCaseStudy.map(cell => (
						<HeroCaseStudy title={cell.title} 
						subtitle={cell.subtitle}
						image={cell.image}/>
					))}
				</SectionIntroCaseStudy>
				<SectionIntroCaseStudy>
					{triberdata.introCaseStudy2.map(cell => (
						<IntroCaseStudy2 title1={cell.title1} 
						description1={cell.description1} title2={cell.title2} 
						description2={cell.description2}
						/>
					))}
				</SectionIntroCaseStudy>
				<SectionTextBlockCentered>
					{triberdata.textBlockCentered.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
				</SectionTextBlockCentered>
				
				<div>
			    <FooterContainer>
			      <LeftContainer>
			        <PreviousLink>
			           <Link to="/agorize-design-system">← Previous project</Link>
			        </PreviousLink>
			      </LeftContainer>
			      <RightContainer>
			        <NextLink>
			          <Link to="/work">Back to Work →</Link>
			        </NextLink>
			      </RightContainer>
			    </FooterContainer>
  			</div>

			</div>
		</Layout>
		)
	}
}